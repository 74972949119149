import { useEffect, useState } from 'react'
import Configs from '@/configs/Configs'
import {
  getItemAsync,
  setItemAsync,
} from '@/lib/SecureStorage'

const useStagingLock = ()=> {

  const [allowed, setAllowed] = useState(true)

  const checkPermission = async()=> {
    if(Configs.env !== 'staging') return

    // lock only on web staging
    setAllowed(false)
    const unlocked = await getItemAsync('staging-unlocked')
    if(unlocked){
      setAllowed(true)
      return
    }

    const result = window.prompt('カシカン（テスト環境）へのアクセスは制限されています。https://casican.meをご利用下さい。', '')
    // we don't care some developer access stagin env, only care the normal users
    // who accidentally access staging env. So it is the easiest way.
    if(result === 'casican-staging'){
      setAllowed(true)
      await setItemAsync('staging-unlocked', 'true')
    }
  }

  useEffect(()=> {
    checkPermission()
  }, [])

  return { allowed }
}

export default  useStagingLock
