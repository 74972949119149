// RootNavigation.js
// https://reactnavigation.org/docs/navigating-without-navigation-prop

import { createNavigationContainerRef } from '@react-navigation/native'
export const navigationRef = createNavigationContainerRef()

export const navigate = (name, params) => {
  if(navigationRef.isReady()){
    navigationRef.navigate(name, params)
  }
}

export const reset = (props) => {
  if(navigationRef.isReady()){
    navigationRef.reset(props)
  }
}

export const resetRoot = (props) => {
  if(navigationRef.isReady()){
    navigationRef.resetRoot(props)
  }
}

export const dispatch = (action) => {
  if(navigationRef.isReady()){
    navigationRef.dispatch(action)
  }
}

const resetToLanding = ()=> {
  resetTo('Landing')
}

// FIXME: resetTo some route do not always work.
export const resetTo = (name)=> {
  reset({
    index: 0,
    routes: [{
      name: 'Main',
      index: 0,
      routes: [{ name }],
    }]
  })
}

export const getCurrentRoute = () => {
  if(navigationRef.isReady()){
    return navigationRef.getCurrentRoute()
  }
  return {}
}

export const getRootState = () => {
  if(navigationRef.isReady()){
    return navigationRef.getRootState()
  }
}

export default {
  navigate,
  dispatch,
  getRootState,
  reset,
  resetRoot,
  resetTo,
  resetToLanding,
  getCurrentRoute,
}
