import useWindowDimensions from 'hooks/useWindowDimensions'
import { useEffect } from 'react'
import { set } from 'features/devices'
import { useDispatch, useSelector } from 'react-redux'

// it uses store to reduce rerenders
const useDynamicGrid = ({
  size,
}) => {

  const dispatch = useDispatch()

  const windowWidth  = useSelector(state => state.devices.windowWidth) || 0
  const windowHeight = useSelector(state => state.devices.windowHeight) || 0

  const { width, height, isHorizontal, } = useWindowDimensions()

  useEffect(()=> {
    set({ windowWidth: width, windowHeight: height })(dispatch)
  }, [width, height])

  //p('useDynamicGrid')
  return {
    width: windowWidth / 12 * size,
    height: windowHeight / 12 * size,
    isHorizontal,
  }

}

export default useDynamicGrid
