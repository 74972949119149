import { GrowthBook } from "@growthbook/growthbook-react"
import Platform from '@/lib/Platform'
import Configs from '@/configs/Configs'
// @ts-ignore fixme
import analytics from '@/lib/analytics'
import {
  getItemAsync,
  setItemAsync,
} from '@/lib/SecureStorage'

export const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-r2jLTDT05IkKCphF",

  // Enable easier debugging during development
  enableDevMode: Configs.env !== "production",
  trackingCallback: (experiment, result) => {
    try{
      analytics().logEvent("experiment_viewed", {
        event_category: "experiment",
        experiment_id: experiment.key,
        variation_id: result.variationId,
      })
      p("Experiment viewed", experiment, result)
    }catch(e){
      pp(e)
    }
  },
})

export const getId = async () => {
  const id = await getItemAsync("growthbook_id")
  if(id) return id

  // generate random string
  const rand = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  await setItemAsync("growthbook_id", rand)
  return rand
}


export const getGA4Id = () => {
  if(Platform.OS !== "web") return null

  return document.cookie.match(/_ga=(.+?);/)?.[1].split('.').slice(-2).join(".")
}

export {
  GrowthBookProvider,
  useFeatureIsOn,
  useFeatureValue,
  useGrowthBook,
  FeaturesReady,
} from "@growthbook/growthbook-react"

