import React from 'react'
import { TouchableOpacity as NativeTouchable, TouchableOpacityProps } from 'react-native'

interface Props extends TouchableOpacityProps {
  activeOpacity?: number
}

const TouchableOpacity: React.FC<Props> = ({
  activeOpacity = 0.4,
  children,
  ...rest
}) => {

  return (
    <NativeTouchable
    activeOpacity={activeOpacity}
    {...rest}
  >
    {children}
    </NativeTouchable>
  )
}

export default TouchableOpacity
