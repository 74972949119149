
export const types = {
  hide: 'tutorials/hide',
  hideSamplePost: 'tutorials/hideSamplePost',
  clear: 'tutorials/clear',
}

const initialState = {
  hidden: false,
  samplePostHidden: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.hide: {
      return{
        ...state,
        hidden: true
      }
    }
    case types.hideSamplePost: {
      return{
        ...state,
        samplePostHidden: true
      }
    }
    case types.clear: {
      return initialState
    }
    default: {
      return state
    }
  }
}
