import Cookies from 'js-cookie'
import Configs from '@/configs/Configs'
import capture from '@/sentry/simpleCapture'

const defaultOptions = {
  ...(__DEV__ ? {} : {
    domain: Configs.webDomain,
    expires: 180,
    secure: true,
    sameSite: 'lax' as const,
  }),
}

type Key = string
type Value = string

export const setItemAsync = async (
  key: Key,
  value: Value,
)=> {
  try{
    return Cookies.set(key, value, defaultOptions)
  }catch(e){
    console.error(e)
    capture(e)
    return null
  }
}

export const getItemAsync = async (key: Key)=> {
  try{
    return Cookies.get(key)
  }catch(e){
    console.error(e)
    capture(e)
    return null
  }
}

export const deleteItemAsync = async(key: Key) => {
  try{
    return Cookies.remove(key, defaultOptions)
  }catch(e){
    console.error(e)
    capture(e)
    return null
  }
}


const SecureStorage = {
  setItemAsync,
  getItemAsync,
  deleteItemAsync,
}

export default SecureStorage
