import React from 'react'
import returnBox from 'assets/images/icon.png'
import View from 'components/View'
import Image from 'components/Image'
import { PropTypes } from 'prop-types'

const AppIcon = props => {

  const {
    size
  } = props

  return (
    <View center style={{
      width: size, height: size,
    }}>
      <Image
        uri={returnBox}
        style={{ backgroundColor: 'rgba(0,0,0,0)' }}
      />
    </View>
  )

}

AppIcon.propTypes = {
  size: PropTypes.number.isRequired,
}

export default AppIcon
