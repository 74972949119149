import * as Device from '@/lib/Device'
import Platform from '@/lib/Platform'
import { useEffect } from 'react'
import { post } from '@/features/requests'
import api from '@/api'
import { useStore, useSelector, RootState } from '@/store'

const deviceParams = {
  platform: Platform.OS,
  // available on app & web
  device: {
    manifacturer: Device.manufacturer,
    modelName: Device.modelName,
    osName: Device.osName,
    osVersion: Device.osVersion,
  },
  // available on app (android & ios)
  app: {
    brand: Device.brand,
    deviceName: Device.deviceName,
    deviceYearClass: Device.deviceYearClass,
    osBuildId: Device.osBuildId,
    osInternalBuildId: Device.osInternalBuildId,
  }
}

const useDeviceTracking = () => {

  const { dispatch, getState } = useStore()
  const userId = useSelector((state: RootState) => state.auth.id)

  useEffect(() => {
    const track = async () => {
      try {
        await post(api.users.device(), deviceParams)(dispatch, getState)
      } catch (e) {
        console.error(e)
      }
    }

    if (userId){
      track()
    }
  }, [userId])

  return deviceParams

}

export default useDeviceTracking
