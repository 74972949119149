import { useState, useEffect } from 'react'
import Platform from 'lib/Platform'
import Keyboard from 'lib/Keyboard'

const useKeyboard = ()=> {

  const [height, setHeight] = useState(0)

  useEffect(()=> {
    if(Platform.OS === 'ios'){
      const keyboardDidShowListener = Keyboard.addListener(
        'keyboardDidShow', keyboardDidShow
      )
      const keyboardWillHideListener = Keyboard.addListener(
        'keyboardWillHide', keyboardWillHide
      )
      return ()=> {
        keyboardDidShowListener.remove()
        keyboardWillHideListener.remove()
      }
    }
  }, [])

  const keyboardDidShow = (e) => {
    setHeight(e.endCoordinates.height)
  }

  const keyboardWillHide = ()=> {
    setHeight(0)
  }

  return { height }
}

export default useKeyboard
