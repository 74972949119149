import { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import useAuth from 'hooks/useAuth'

import { useStore, useSelector } from 'react-redux'
import { fetchCurrentUser, signOut } from 'features/auth'
import jwt from 'utils/jwt'
import useDeviceTracking from 'hooks/useDeviceTracking'

const AuthGate = props => {

  useDeviceTracking()

  const loaded = useAuth()
  const { dispatch, getState } = useStore()
  const [completed, setCompleted] = useState(false)
  const token = useSelector(state => state.auth.token)

  const setCurrentUser = async()=> {
    await fetchCurrentUser(dispatch, getState)
  }

  useEffect(()=> {
    // always clear sync state on app initialization
    dispatch({ type: 'sync/clear' })

    // wait for the auth token to be loaded from SecureStorage
    // which uses  ExpoSecureStore on Native and secure cookie on Web
    // SecureStore is async so make sure it is loaded with useAuth()
    if(!loaded) return
    setCompleted(true)

    if(token){
      if(jwt.isExpired(token)){
        signOut()(dispatch, getState)
      }else{
        setCurrentUser()
      }
    }
  }, [loaded])

  return completed ? props.children : null
}

AuthGate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthGate
