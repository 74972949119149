import { useEffect, useState } from 'react'

import AppState from 'lib/AppState'
import Updates from 'lib/Updates'
import Alert from 'lib/Alert'
import Configs from 'configs/Configs'
import { capture } from 'sentry'
import { t } from '@/i18n'

const major = (version) => {
  return parseInt(version.split('.')[0])
}

const minor = (version)=> {
  return parseInt(version.split('.')[1])
}

// check for change major.minor.patch
// notify if more than minor has changed.
const shouldForceUpdate = (current, latest) => {
  if(!current || !latest) return false

  // update when major version is updated
  // major update require a build, so it will not happen
  if(major(current) < major(latest)) return true

  // major version is same
  if(major(current) === major(latest)){
    // update when minor version is updated
    if(minor(current) < minor(latest)) return true
  }

  // do not update on any other conditions
  return false
}

// useUpdate does not work as expected anymore, since expo-updates are changed
const useUpdate = ()=> {
  const [isAvailable, setIsAvailable] = useState(false)

  // true if it is more than minor version update (not patch)
  const [isMinor, setIsMinor] = useState(false)

  const askToReload = ()=> {
    Alert.alert(
      t('alerts.update.title'),
      t('alerts.update.description'),
      [
        { text: t('alerts.update.ok'), onPress: async()=> {
          await Updates.reloadAsync()
        } },
        { text: t('alerts.update.cancel'), onPress: ()=>{}, style: 'cancel'}
      ]
    )
  }

  const check = async() => {
    try{
      if(Configs.env === 'development') return

      const checked = await Updates.checkForUpdateAsync()
      if(checked.isAvailable){
        await Updates.fetchUpdateAsync()
        setIsAvailable(true)
      }else{
        setIsAvailable(false)
        setIsMinor(false)
      }
      // always show if it is a staging
      if(Configs.env === 'staging' && checked?.isAvailable){
        setIsMinor(true)
      }

    }catch(err){
      console.error(err)
      capture(err)
    }
  }

  const handleStateChange = (next)=> {
    if (next === 'active') {
      check()
    }
  }

  useEffect(()=> {
    // check on app start
    check()
    // check on app return from background
    const sub = AppState.addEventListener('change', handleStateChange)
    return () => {
      sub?.remove?.()
    }
  }, [])

  return {
    isAvailable,
    isMinor: isAvailable && isMinor,
    askToReload,
  }

}

export default useUpdate
