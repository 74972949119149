import React from 'react'
import WebModal from 'modal-react-native-web'

WebModal.setAppElement('#root')

const Modal = props => {

  const {
    children,
    ...rest
  } = props

  return (
    <WebModal
      transparent={true}
      animationType='fade'
      {...rest}
    >
      {children}
    </WebModal>
  )
}

export default Modal
