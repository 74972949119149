import React from 'react'
import View from 'components/View'
import Icon from 'components/Icon'
import Text from 'components/Text'

import { styles } from 'styles'

const IconWithLabel = props => {

  const {
    name,
    label,
    badge,
    color,
    containerStyle,
    regular,
  } = props

  return (
    <View flex center style={{
      ...containerStyle
    }}>
      <Icon
        regular={regular}
        name={name}
        badge={badge}
        color={color}
      />
      {label ? (
        <Text
          size='xxs'
          color={color}
          style={{
            marginTop: styles.space.xxs,
            flexWrap: 'wrap',
            textAlign: 'center',
            maxWidth: 50,
            // prevent label to extend too much
            lineHeight: styles.size.xs,
          }}
        >
          {label}
        </Text>
      ) : null}
    </View>
  )

}

export default IconWithLabel
