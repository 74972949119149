import { StyleSheet, StatusBar } from 'react-native'
import Platform from 'lib/Platform'
import Dimensions from 'lib/Dimensions'

export const wideThreshold = 1024

const isWeb = Platform.OS === 'web'

export const headerHeight = 45
const statusBarHeight = StatusBar.currentHeight || 0
const headerHeightWithStatusBar = headerHeight + statusBarHeight

const windowWidth = Dimensions.get('window').width
const window = {
  width:  (isWeb && windowWidth > wideThreshold) ? wideThreshold : windowWidth,
  height: Dimensions.get('window').height - statusBarHeight,
}

const division = 1 / 12

const fixed = 'absolute'

const baseSize = 15
const multiplier = 1.2
const negativeMultiplier = 0.8
const hairlineWidth = StyleSheet.hairlineWidth

export const size = {
  tiny: baseSize * negativeMultiplier * negativeMultiplier * negativeMultiplier,
  xxs: baseSize * negativeMultiplier * negativeMultiplier,
  xs: baseSize * negativeMultiplier,
  sm: baseSize,
  md: baseSize * multiplier,
  lg: baseSize * multiplier * multiplier,
  xl: baseSize * multiplier * multiplier * multiplier,
  xxl: baseSize * multiplier * multiplier * multiplier * multiplier,
}

const avatarSize = {
  xxs: 12,
  xs: 24,
  sm: 36,
  md: 48,
  lg: 60,
  xl: 82,
}

const button = {
  md: 42,
}

export const space = {
  xxs: 1,
  xs: 3,
  sm: 5,
  md: 10,
  lg: 25,
  xl: 40,
}

export const radius = {
  sm: 4,
  md: 8,
  lg: 12,
  xl: 25,
}

export const color = {

  // based on primary to #4461a0
  // https://material.io/design/color/the-color-system.html#tools-for-picking-colors

  light: {
    brand: 'rgb(68, 97, 160)',
    primary: 'rgb(68, 97, 160)',
    primaryVar: '#6a9fd9',
    secondary: '#03dac6',
    secondaryVar: '#018786',
    background: '#fff',
    surface: '#fff',
    error: '#b00020',
    notice: '#FFF8E1', // amber 50,
    onPrimary: '#fff',
    onSecondary: '#000',
    onBackground: '#000',
    onSurface: '#000',
    onError: '#fff',
    label: 'rgb(88,88,88)',
    greyLight: '#e0e0e0',
    overlay: {
      one: 'rgba(255,255,255,0.05)',
      two: 'rgba(255,255,255,0.2)',
      three: 'rgba(255,255,255,0.3)',
      nine: 'rgba(255,255,255,0.92)',
    },
    star: '#ffa41c',
  },

  dark: {
    brand: 'rgb(68, 97, 160)',
    primary: 'rgb(68, 97, 160)',
    primaryVar: '#6a9fd9',
    secondary: '#03dac6',
    secondaryVar: '#018786',
    background: '#fff',
    surface: '#fff',
    error: '#b00020',
    notice: '#FFF8E1', // amber 50,
    onPrimary: '#fff',
    onSecondary: '#000',
    onBackground: '#000',
    onSurface: '#000',
    onError: '#fff',
    label: 'rgb(88,88,88)',
    greyLight: '#e0e0e0',
    overlay: {
      one: 'rgba(255,255,255,0.05)',
      two: 'rgba(255,255,255,0.2)',
      three: 'rgba(255,255,255,0.3)',
      nine: 'rgba(255,255,255,0.92)',
    },
    star: '#ffa41c',
  },

  // dark: {
  //   brand: 'rgb(126, 152, 208)',
  //   primary: 'rgb(126, 152, 208)',
  //   primaryVar: '#3560b5',
  //   secondary: '#03dac6',
  //   secondaryVar: '#03dac6',
  //   background: '#121212',
  //   surface: '#121212',
  //   error: '#cf6679',
  //   onPrimary: '#000',
  //   onSecondary: '#000',
  //   onBackground: '#fff',
  //   onSurface: '#fff',
  //   onError: '#000',
  //   label: '#e0e0e0',
  //   overlay: {
  //     one: 'rgba(0,0,0,0.05)',
  //   },
  // },

  // DEPRECATED
  white: '#fff',
  submit: 'rgb(69, 123, 157)',
  grey: '#777',
  greyLight: 'rgb(230, 230, 230)',
  greyTrans: 'rgba(0,0,0,0.5)',
  border: 'rgb(216,216,216)',
  danger: '#E63946',
  // dark: 'rgb(35,13,0)',
}

export const height = {
  header: {
    height: headerHeightWithStatusBar,
  },
  keyboardNav: {
    height: size.sm + space.md * 2
  },
  statusBar: { height: statusBarHeight },
  one:    { height: window.height * division * 1  },
  two:    { height: window.height * division * 2  },
  three:  { height: window.height * division * 3  },
  four:   { height: window.height * division * 4  },
  five:   { height: window.height * division * 5  },
  six:    { height: window.height * division * 6  },
  seven:  { height: window.height * division * 7  },
  eight:  { height: window.height * division * 8  },
  nine:   { height: window.height * division * 9  },
  ten:    { height: window.height * division * 10 },
  eleven: { height: window.height * division * 11 },
  full:   { height: window.height * division * 12 },
}

export const width = {
  hairlineWidth,
  one:    { width: window.width * division * 1  },
  two:    { width: window.width * division * 2  },
  three:  { width: window.width * division * 3  },
  four:   { width: window.width * division * 4  },
  five:   { width: window.width * division * 5  },
  six:    { width: window.width * division * 6  },
  seven:  { width: window.width * division * 7  },
  eight:  { width: window.width * division * 8  },
  nine:   { width: window.width * division * 9  },
  ten:    { width: window.width * division * 10 },
  eleven: { width: window.width * division * 11 },
  full:   { width: window.width * division * 12 },
}

export const shadow = {
  top: {
    shadowColor: 'rgba(0,0,0,0.25)',
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    elevation: 7
  },
  card: {
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    elevation: 3,
  }
}

export const styles = {
  color,
  size,
  space, // TODO: remove or integrate into styles
  avatarSize,
  button,
  radius,
  height,
  width,

  fixed: {
    top:      { position: fixed, top: 0, left: 0, right: 0 },
    topRight: { position: fixed, top: 0, right: 0, },
    topLeft:  { position: fixed, top: 0, left: 0, },
    bottom:   { position: fixed, bottom: 0, left: 0, right: 0, },
    bottomRight: { position: fixed, bottom: 0, right: 0, },
    left:     { position: fixed, left: 0, top: 0, bottom: 0 },
    right:    { position: fixed, right: 0, top: 0, bottom: 0 },
    center:   { position: fixed, right: 0, top: 0, bottom: 0, left: 0, },
  },
  absolute: {
    topLeft: { position: 'absolute', top: 0, left: 0, },
    topRight: { position: 'absolute', top: 0, right: 0, },
    bottomLeft: { position: 'absolute', bottom: 0, left: 0, },
    bottomRight: { position: 'absolute', bottom: 0, right: 0, },
  },

  border: {
    box: {
      borderStyle: 'solid',
      borderWidth: hairlineWidth,
      borderColor: color.border,
    },
    bottom: {
      borderBottomStyle: 'solid',
      borderBottomWidth: hairlineWidth,
      borderBottomColor: color.border,
    },

    left: {
      borderLeftStyle: 'solid',
      borderLeftWidth: hairlineWidth,
      borderLeftColor: color.border,
    },

    right: {
      borderRightStyle: 'solid',
      borderRightWidth: hairlineWidth,
      borderRightColor: color.border,
    },

  },

  shadow: {
    shadowColor: 'rgba(0,0,0,0.25)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    elevation: 3
  },

  shadowCard: {
    shadowColor: 'rgba(0,0,0,0.1)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    elevation: 1
  },

  shadowTop: shadow.top,

}

export default styles
