import { useState } from 'react'
import { PropTypes } from 'prop-types'
import UserImage from 'components/users/Image'
import View from 'components/View'
import Modal from 'components/modals/Modal'
import DialogContainer from 'components/modals/DialogContainer'
import MenuItem from 'components/MenuItem'
import Icon from 'components/Icon'
import Text from 'components/Text'
import TouchableOpacity from 'components/TouchableOpacity'
import UserIconWithName from 'components/users/IconWithName'
import SectionHeader from 'components/SectionHeader'
import LoadingCover from 'components/modals/LoadingCover'
import Button from 'components/Button'
import IconWithLabel from 'components/IconWithLabel'

import { useSelector, useStore } from 'react-redux'
import jwt from 'utils/jwt'
import useDynamicGrid from 'hooks/useDynamicGrid'
import { t } from '@/i18n'
import { useNavigation } from '@react-navigation/native'
import { loginAs, signOutAll, } from 'features/auth'
import Platform from 'lib/Platform'
import styles from 'styles'

const UserIconButton = () => {

  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigation = useNavigation()
  const { dispatch, getState } = useStore()

  const width = useDynamicGrid({ size: 1 }).width * 1.6

  const token = useSelector(state => state.auth.token)
  const authId = jwt.parse(token).slug
  const accountIds = useSelector(state => state.accounts.ids?.length > 0 ? state.accounts.ids : [authId])
  const accountsDisabled = useSelector(state => state.devices.accountsDisabled)

  const onPressUserIcon = ()=> {
    if(accountsDisabled){
      navigation.navigate('User', { id: authId })
    }else{
      setIsOpen(true)
    }
  }

  const navigateTo = (name, params) => {
    setIsOpen(false)
    navigation.navigate(name, params)
  }

  const onPressAccount = async(id) => {
    if(id === authId){
      navigateTo('User', { id })
    }else{
      setIsOpen(false)
      // ios won't close modal around navigation.
      if(Platform.OS !== 'ios'){ setLoading(true) }
      try{
        await loginAs({ id })(dispatch, getState)
      }catch(e){
        capture(e)
      }finally{
        if(Platform.OS !== 'ios'){ setLoading(false) }
      }
    }
  }

  const onPressSignOutAll = async()=> {
    await signOutAll()(dispatch, getState)
    navigation.navigate('Landing')
  }

  if(!authId) return null

  const [currentId, ...candidateIds] = accountIds
  return (
    <>
      <View center style={{ width }}>
        <TouchableOpacity testID='touchable-user-image' onPress={onPressUserIcon}>
          <UserImage linked={false} userId={authId} size='sm' />
        </TouchableOpacity>
      </View>

      <Modal testID='modal-accounts' visible={isOpen}>
        <DialogContainer onDismiss={()=> setIsOpen(false)}>

          <SectionHeader label={t('labels.user.current')} />
          <MenuItem key={currentId} testID={`menu-item-${currentId}`}
            onPress={()=> onPressAccount(currentId)}
            containerStyle={{ width: '100%' }}
            rightIcon={(
              <TouchableOpacity
                onPress={()=> navigateTo('UserEdit', { id: currentId })}
                style={{ padding: styles.space.md }}
              >
                <IconWithLabel
                  color={styles.color.light.label}
                  name='cog'
                  label={t('general.setting')}
                />
              </TouchableOpacity>
            )}
          >
            <UserIconWithName userId={currentId} size='md' />
          </MenuItem>

          <SectionHeader label={t('labels.user.switch')} />
          {candidateIds.map((id)=> (
            <MenuItem key={id} testID={`menu-item-${id}`}
              onPress={()=> onPressAccount(id)}>
              <UserIconWithName userId={id} size='md' />
            </MenuItem>
          ))}

          <MenuItem borderless leftIcon={<Icon name='plus' />}
            onPress={()=> navigateTo('SignInMethods')}
          >
            <Text>
              {t('labels.user.add')}
            </Text>
          </MenuItem>

          <View row center>
            <Button inverted marginless testID='button-sign-out-all'
              size='sm'
              label={t('labels.accounts.signOutAll')}
              style={{
                width: '75%',
                marginTop: styles.space.sm,
                marginBottom: styles.space.sm
              }}
              onPress={onPressSignOutAll}
              loading={false}
            />
          </View>

        </DialogContainer>
      </Modal>
      <LoadingCover loading={loading} />
    </>
  )

}

UserIconButton.propTypes = {

}

export default UserIconButton
