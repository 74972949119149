
// warn if the object value is boolean
export const checkParams = (params?: object) => {
  if(!params) return
  if(typeof params !== 'object') return

  Object.keys(params).forEach((key) => {
    // @ts-ignore
    if (typeof params[key] === 'boolean') {
      // using bool on params causes inconsistent behavior on web and native
      // web: bool value is converted to string ('true' or 'false')
      // native: bool value is converted to int (1 or 0)
      pWarn('analytics: boolean value for params, use string instead')
      pWarn({key})
    }
  })
}


export const checkName = (name: string): string => {
  if(name.length > 40){
    pWarn('analytics: name.length > 40', name)
    return name.substring(0, 40)
  }
  return name
}

