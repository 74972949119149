import { useEffect, useState } from 'react'
import Linking from 'lib/Linking'
import { login } from 'features/auth'
import RootNavigation from 'navigation/RootNavigation'

// basic navigation to each screen is handled by react-navigation
// check navigation/LinkingConfiguration config#getInitialURL
const useLinkHandler = (store) => {

  const dispatch = store.dispatch
  const getState = store.getState

  // Linking listner was fireing multiple times so prevent it by timing
  const [prevent, setPrevent] = useState(false)

  const handleURL = async({ url })=> {
    if(prevent || !url) return

    const { queryParams } = Linking.parse(url)
    const { type, token } = queryParams
    switch (type) {
      case 'auth': {
        await handleLogin(token)
      }
    }
  }

  const handleLogin = async(token)=> {
    setPrevent(true)
    setTimeout(()=> {
      setPrevent(false)
    }, 500)
    const result = await login({ data: { token }, dispatch, getState })
    if(!result.ok){
      RootNavigation.reset({
        index: 0,
        routes: [ { name: 'SignIn' }]
      })
    }
  }

  // when the app is not started and started with the link
  const checkInitial = async() => {
    const url = await Linking.getInitialURL()
    // token becomes nil at features/auth#fetchCurrentUser
    // due to failing to load store right after the app starts.
    // setting the timeout fixed issue, but maybe not ideal.
    setTimeout(async()=> {
      await handleURL({ url })
    }, 250)
  }

  useEffect(()=> {
    checkInitial()
  }, [])

  useEffect(()=> {
    // when the app is open and become foreground
    const sub = Linking.addEventListener('url', handleURL)
    return ()=> {
      sub?.remove?.()
    }
  }, [prevent])

}

export default useLinkHandler
