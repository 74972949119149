import React, { useState, useEffect } from 'react'
import Keyboard from 'lib/Keyboard'
import Text from 'components/Text'
import View from 'components/View'
import TouchableOpacity from 'components/TouchableOpacity'
import useColorScheme from 'hooks/useColorScheme'
import { styles } from 'styles'
import Platform from 'lib/Platform'
import { t } from '@/i18n'

const KeyboardNav = () => {

  const theme = useColorScheme()

  const [shown, setShown] = useState(false)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const show = Keyboard.addListener("keyboardDidShow", keyboardDidShow)
    const hide = Keyboard.addListener("keyboardWillHide", keyboardWillHide)
    const didHide = Keyboard.addListener("keyboardDidHide", keyboardDidHide)

    // cleanup function
    return () => {
      show?.remove?.()
      hide?.remove?.()
      didHide?.remove?.()
    }
  }, [])


  const keyboardDidShow = (e) => {
    setHeight(e.endCoordinates.height)
    setShown(true)
  }

  // willHide do not trigger in android
  const keyboardWillHide = () => {
    setShown(false)
    setHeight(0)
  }

  const keyboardDidHide = ()=> {
    setShown(false)
  }

  const dismiss = ()=> {
    Keyboard.dismiss()
  }

  return (
    <>
      <View style={{
        height: shown ? styles.height.keyboardNav.height : 0,
        backgroundColor: styles.color[theme].surface,
        overflow: 'hidden',
        // position: 'absolute' will prevent keyboard to be dismissed when focusing on the text input.
        // keyboarNav will be pushed up for android, but not on iOS
        ...(Platform.OS === 'ios' ? {
          position: 'absolute', right:0, left:0, bottom: height
        } : {}),
      }}>
        <View flex row hend vcenter style={{
          backgroundColor: 'rgba(0,0,0,0.05)',
        }}>
          <TouchableOpacity
            onPress={dismiss}
            style={{
              padding: styles.space.sm,
              paddingRight: styles.space.md,
            }}
          >
            <Text size='sm' color='#5fc9f8'>{t('general.close')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  )

}

export default KeyboardNav
