import { t } from '@/i18n'

const types = {
  set: 'errors/set',
  clear: 'errors/clear',
}

// errors: {
//  good: {
//    title: ['is required',]
//  }
// }
const initialState = {
  // it is used on new, edit and it will be shared across
  user: null,
  good: null,
  booking: null,
  message: null,
  group: null,
  payment: null,

  // it is used for bulk updates
  goods: {},
}

export const setPaymentError = (error)=> {
  return dispatch => {
    if(!error) return

    if(error.code === 'payment_intent_unexpected_state' || error.code === 'Canceled'){
      dispatch(set({
        payment: {
          status: [ t('errors.payment.sessionTimeout') ]
        }
      }))
    }else{
      dispatch(set({
        payment: {
          status: [ error.message ]
        }
      }))
    }
  }
}

export const set = (errors) => {
  p('features/errors/set', errors)
  return {
    type: types.set,
    errors,
  }
}

export const clear = () => {
  return { type: types.clear }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.set: {
      return {
        ...state,
        ...action.errors,
      }
    }
    case types.clear: {
      return initialState
    }
    default:
      return state
  }
}
