
export const setUnread = (unread) => {
  return dispatch => {
    dispatch({
      type: types.setUnread,
      unread,
    })
  }
}

export const notifications = {
  setUnread,
}

const types = {
  setUnread: 'notifications/setUnread',
  clear: 'notifications/clear',
}

const initialState = {
  unread: false
}


export default (state = initialState, action) => {
  switch (action.type) {
    case types.setUnread: {
      return{
        ...state,
        unread: action.unread,
      }
    }
    case types.clear: {
      return initialState
    }
    default: {
      return state
    }
  }
}
