import {
  getItemAsync,
  setItemAsync,
  deleteItemAsync,
} from '@/lib/SecureStorage'
import Configs from '@/configs/Configs'
import Platform from '@/lib/Platform'
import { capture } from '@/sentry'

let domain = Platform.OS === 'web' ? Configs.domain : Configs.webDomain
if(__DEV__){
  domain = domain.split(':')[0] // : is not allowed on SecureStorage
}
const secureKeyBase = `_${domain}_`
const idKey = `${secureKeyBase}id`
const tokenKey = `${secureKeyBase}token`

const setId = async({
  id
}: {
  id: string,
})=> {
  return await setItemAsync(idKey, id)
}

const setToken = async({
  token
}: {
  token: string,
})=> {
  return await setItemAsync(tokenKey, token)
}

const getAuthTokenFor = async({
  id
}: {
  id: string,
}) => {
  return await getItemAsync(`${secureKeyBase}${id}`)
}

const setAuthTokenFor = async({
  id,
  token
}: {
  id: string,
  token: string,
}) => {
  return await setItemAsync(`${secureKeyBase}${id}`, token)
}

const getAuth = async()=> {
  const id = await getItemAsync(idKey)
  const token = await getItemAsync(tokenKey)
  return {
    id,
    token,
  }
}

const removeAuth = async({
  id
}: {
  id: string,
})=> {
  await deleteItemAsync(idKey)
  await deleteItemAsync(tokenKey)
  await deleteItemAsync(`${secureKeyBase}${id}`)
  return true
}

const removeI18n = async()=> {
  return await deleteItemAsync('i18n')
}

export const storage = {
  setId,
  setToken,
  getAuth,
  setAuthTokenFor,
  getAuthTokenFor,
  removeAuth,
  removeI18n,
}
