import React from 'react'
import { PropTypes } from 'prop-types'
import View from 'components/View'
import { headerHeight } from 'styles'

const HeaderRightContainer = props => {
  return (
    <View row style={{
      // if you set the height lower than 44 then the header location
      // moves around when rotating the device.
      height: headerHeight,
    }}>
      {props.children}
    </View>
  )
}

HeaderRightContainer.propTypes = {
  children: PropTypes.node,
}

export default HeaderRightContainer
