import React from 'react'
import Image from 'components/Image'
import Link from 'components/Link'
import Text from 'components/Text'
import View from 'components/View'
import Icon from 'components/Icon'

import { styles } from 'styles'
import { useSelector } from 'react-redux'

export const DummyIcon = ({ size })=> {

  return(
    <View center style={{
      backgroundColor: styles.color.greyLight,
      width: size, height: size,
      borderRadius: size,
      overflow: 'hidden'
    }}>
      <Icon regular inverted name='user' size='sm' />
    </View>
  )

}

// it takes user or local uri as a prop and render image
const UserImage = props => {

  const {
    userId,
    linked = true,
    uri, // uri could be given when editing
  } = props
  const size = styles.avatarSize[props.size] || styles.avatarSize.md

  const name = useSelector(state => {
    return state.entities.users?.[userId]?.name
  }) || ''

  const url = useSelector(state => {
    return state.entities.users?.[userId]?.thumbnail_url
  }) || ''

  let img = (
    <Image
      resizeMode='cover'
      uri={uri || url}
    />
  )

  if(!uri && !url){ // default user image
    if(name){
      img = (
        <View center style={{
          backgroundColor: styles.color.greyLight,
        }}>

          <Text label
            style={{
              width: size, height: size + 5,
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center', // need for web
              fontSize: size * 0.5,
              lineHeight: size + 6,
            }}
          >
            {name?.substring?.(0,1)}
          </Text>
        </View>
      )
    }else{ // when non of the information is available
      img = <DummyIcon size={size} />
    }
  }

  return (
    <View style={{
      width: size,
      height: size,
      borderRadius: size,
      overflow: 'hidden'
    }}>
      {linked ? (
        <Link to='User' params={{ id: userId }}
          style={{ width: size, height: size + 3 }}
        >
          {img}
        </Link>
      ) : img}
    </View>
  )

}

import { PropTypes } from 'prop-types'
UserImage.propTypes = {
  userId: PropTypes.string, // could be undefined
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'xl']),
  linked: PropTypes.bool, // link can be disabled
  uri: PropTypes.string, // it will override user if given
}

export default UserImage
