
// message: { type: str, body: str }
export const add = (message, timeout) => {
  return (dispatch) => {
    dispatch({
      type: types.add,
      message
    })
    let delay = timeout
    // longer the message, slower to dismiss
    if(!delay){
      const charsPerSecond = 4 // opted for Japanese, 5 = average kids
      const totalSeconds = message.body.length / charsPerSecond
      delay = totalSeconds * 1000 // ms
    }
    setTimeout(()=>{
      dispatch(shift())
    }, delay)
  }
}

export const shift = ()=> {
  return { type: types.shift }
}

const remove = (index) => {
  return { type: types.remove, index }
}

export const flash = {
  add, shift, remove
}

const types = {
  add: 'flash/add',
  shift: 'flash/shift',
  remove: 'flash/remove',
  clear: 'flash/clear',
}

// { type: 'error|success', body: str, title: str }
const initialState = {
  messages: []
}


export default (state = initialState, action) => {
  switch (action.type) {
    case types.add: {
      const prev = state.messages[state.messages.length - 1]
      // do not show the same message again and again
      if(prev?.body === action.message.body){
        const messages = [
          ...state.messages.slice(0, state.messages.length-1),
          action.message
        ]
        return { ...state, messages }
      }

      return{
        ...state,
        messages: [
          ...state.messages,
          action.message
        ]
      }
    }
    case types.shift: {
      return {
        messages: [
          ...state.messages.slice(1, state.messages.length)
        ]
      }
    }
    case types.remove: {
      return {
        messages: [
          ...state.messages.slice(0, action.index),
          ...state.messages.slice(action.index + 1),
        ]
      }
    }
    case types.clear: {
      return initialState
    }
    default: {
      return state
    }
  }
}
