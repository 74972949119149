import React from 'react'
import View from 'components/View'
import IconButton from 'components/IconButton'
import Text from 'components/Text'
import ItemContainer from './ItemContainer'
import { useDispatch, useSelector } from 'react-redux'
import { styles } from 'styles'
import { space } from 'styles/constants'
import useColorScheme from 'hooks/useColorScheme'
import { PropTypes } from 'prop-types'
import { flash } from 'features/flash'

const FlashItem = props => {

  const { title, body, index, inverted } = props
  const dispatch = useDispatch()

  const dismiss = ()=> {
    dispatch(flash.remove(index))
  }

  return (
    <View row spaceBetween>
      <View vcenter style={{flex: 11}}>
        {title ? (
          <Text size='sm' bold
            inverted={inverted}
            style={{
              marginBottom: styles.space.sm,
            }}
          >
            {title}
          </Text>
        ) : null}
        <Text size='sm'
          id={`text-flash-${index}`}
          inverted={inverted}
        >
          {body}
        </Text>
      </View>
      <View center style={{flex: 1}}>
        <IconButton transparent
          nativeID='icon-button-dismiss-flash'
          size='xs'
          inverted={inverted}
          id={`icon-button-dismiss-${index}`}
          name='times'
          onPress={dismiss}
        />
      </View>
    </View>
  )

}

FlashItem.propTypes = {
  index: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
  title: PropTypes.string,
  inverted: PropTypes.bool,
}

const Flash = () => {

  const theme = useColorScheme()

  const colorMap = {
    error: styles.color[theme].error,
    success: styles.color[theme].primary,
    notice: styles.color[theme].primary,
    //push: 'rgb(242,242,247)',
    push: styles.color[theme].surface,
  }

  const invertedMap = {
    error: true,
    success: true,
    notice: true,
    push: false
  }

  const messages = useSelector(state => state.flash.messages)

  // const messages = [
  //   { type: 'push', body: 'test'},
  //   { type: 'error', body: 'test'},
  //   { type: 'success', body: 'test'},
  // ]

  return (
    <>
      {messages.map((message, i) => (
        <ItemContainer key={i}
          color={colorMap[message.type]}
          style={{
            width: '95%',
            marginTop: space[2],
          }}
        >
          <FlashItem
            index={i}
            title={message.title}
            body={message.body}
            inverted={invertedMap[message.type]}
          />
        </ItemContainer>
      ))}
    </>
  )

}

export default Flash
