import {
  NavigationContainer,
  DefaultTheme,
} from '@react-navigation/native'
import createStackNavigator from 'navigation/createStackNavigator'
import { useRef, } from 'react'

import LinkingConfiguration from './LinkingConfiguration'
// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started

import { navigationRef, } from 'navigation/RootNavigation'
import MainStackScreen from 'navigation/MainNavigation'

import { styles } from 'styles'
import { PropTypes } from 'prop-types'
import analytics from 'lib/analytics'
import { growthbook } from 'lib/GrowthBook'

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const RootStack = createStackNavigator()
const RootNavigator = ()=> {
  return (
    <RootStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <RootStack.Screen
        name='Main'
        component={MainStackScreen}
        options={{ title: '' }}
      />

    </RootStack.Navigator>
  )
}

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: styles.color.light.background,
  },
}

// DarkTheme is currently disabled
// const MyDarkTheme = {
//   ...DarkTheme,
//   colors: {
//     ...DarkTheme.colors,
//     background: styles.color.dark.background,
//   }
// }

export default function Navigation({
  colorScheme,
}) {
  p('Navigation')

  const routeNameRef = useRef()

  const onStateChange = async(state) => {
    const previousRouteName = routeNameRef.current
    const currentRouteName = navigationRef.getCurrentRoute().name

    if (previousRouteName !== currentRouteName) {
      analytics().logEvent('screen_view', { screen_name: currentRouteName })
    }

    // Save the current route name for later comparison
    routeNameRef.current = currentRouteName

    growthbook?.setAttributes({
      ...growthbook?.getAttributes(),
      url: window?.location?.href
    })
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={onStateChange}

      linking={LinkingConfiguration}
      //theme={colorScheme === 'dark' ? MyDarkTheme : MyTheme}
      theme={MyTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  )
}

Navigation.propTypes = {
  colorScheme: PropTypes.string.isRequired,
}

