import React from 'react'
import { PropTypes } from 'prop-types'
import TouchableOpacity from 'components/TouchableOpacity'
import DialogBox from 'components/DialogBox'
import Background from 'components/modals/Background'
import ScrollView from 'components/ScrollView'

import useKeyboard from 'hooks/useKeyboard'

const DialogContainer = props => {

  const { height } = useKeyboard()

  const {
    onDismiss,
    children,
    paddingless,
    fitToContent,
    boxStyle,
  } = props

  return (
    <Background onPress={onDismiss}>
      <DialogBox paddingless={paddingless} style={{
        ...(fitToContent ? {} : { width: '100%', }),
        ...boxStyle,
        marginBottom: height,
      }}>
        <TouchableOpacity activeOpacity={1}>
          <ScrollView
            style={{flexGrow: 0}}
          >
            {children}
          </ScrollView>
        </TouchableOpacity>
      </DialogBox>
    </Background>
  )

}

DialogContainer.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  paddingless: PropTypes.bool,
  fitToContent: PropTypes.bool,
}

export default DialogContainer
