import React from 'react'
import NavIconButton from 'navigation/buttons/IconButton'

import { useSelector, useStore } from 'react-redux'
import { sync } from 'features/sync'
import { notifications } from 'features/notifications'
import { t } from '@/i18n'
import useFocusEffect from 'hooks/useFocusEffect'
import { capture } from 'sentry'

const BookingsNavigationIconButton = () => {

  const authId = useSelector(state => state.auth.id)
  const unread = useSelector(state => state.notifications.unread)

  const { dispatch, getState } = useStore()

  useFocusEffect(
    React.useCallback(() => {
      const load = async ()=> {
        if(!authId) return
        try{
          p('load notifications badge')
          const json = await sync.graphql({
            query: `{ badge{ hasUnread } }`
          })(dispatch, getState)

          if(!json) return

          if(json.data?.badge?.hasUnread){
            notifications.setUnread(true)(dispatch)
          }else{
            notifications.setUnread(false)(dispatch)
          }
        }catch(err){
          ppError(err)
          capture(err)
        }
      }
      // Do something when the screen is focused
      load()
      return () => {
        // Do something when the screen is unfocused
      }
    }, [authId])
  )


  return (
    <NavIconButton regular
      to='Bookings'
      name='calendar-alt'
      badge={unread}
      label={t('labels.booking.list')}
    />
  )
}

export default BookingsNavigationIconButton
