import React from 'react'

import NavIconButton from 'navigation/buttons/IconButton'
import useUpdate from 'hooks/useUpdate'
import { t } from '@/i18n'

const Update = () => {

  const {
    isAvailable,
    isMinor,
    askToReload,
  } = useUpdate()

  if(!isAvailable || !isMinor) return null

  return (
    <NavIconButton
      onPress={askToReload}
      name='sync'
      label={t('labels.update.new')}
    />
  )
}

export default Update
