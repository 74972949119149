
const initialState = {
  home: [],
}

export const prepend = ({
  key,
  id,
})=> {
  return (dispatch)=> {
    p('features/paginations#prepend', { key, id })
    dispatch({
      type: types.prepend,
      key,
      id,
    })
  }
}

const types = {
  prepend: 'paginations/prepend',
  clear: 'paginations/clear'
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.prepend: {
      return {
        ...state,
        [action.key]: [
          action.id,
          ...state[action.key]
        ]
      }
    }

    case types.clear: {
      return initialState
    }

    default: {
      return state
    }
  }
}
