
const types = {
  set: 'updates/set',
  clear: 'updates/clear',
}

const initialState = {
  isAvailable: false
}

export const set = (update) => {
  return {
    type: types.set,
    update,
  }
}

export const clear = () => {
  return { type: types.clear }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.set: {
      return action.update
    }
    case types.clear: {
      return initialState
    }
    default:
      return state
  }
}

