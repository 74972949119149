import React from 'react'
import { ActivityIndicator as NativeIndicator } from 'react-native'
const ActivityIndicator = props => {

  const {
    inverted,
    size,
    style,
  } = props

  let {
    color = '#888',
  } = props

  if(inverted){
    color= '#fff'
  }

  return (
    <NativeIndicator color={color} size={size} style={style} />
  )

}

export default ActivityIndicator
