import React from 'react'
import View from 'components/View'
import { styles } from 'styles'
import PropTypes from 'prop-types'

import useColorScheme from 'hooks/useColorScheme'

const ItemContainer = props => {

  const theme = useColorScheme()

  const {
    color,
    children,
    style,
  } = props

  return(
    <View style={{
      backgroundColor: color,
      marginTop: styles.space.md,
      marginLeft: styles.space.md,
      marginRight: styles.space.md,
      borderRadius: styles.space.sm,
      width: '100%',
      maxWidth: 1000,
      ...styles.shadow,
      ...style,
    }}>

      <View style={{
        backgroundColor: styles.color[theme].overlay.three,
        padding: styles.space.md,
        borderRadius: styles.space.sm,
      }}>
        {children}
      </View>
    </View>
  )

}

ItemContainer.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ItemContainer
