import { memo } from 'react'
import NavIconButton from 'navigation/buttons/IconButton'
import BookingsIconButton from 'navigation/buttons/BookingsIconButton'
import UserIconButton from 'navigation/buttons/UserIconButton'
import Update from 'components/announcements/Update'

import { useSelector } from 'react-redux'
import { t } from '@/i18n'

const HomeNav = () => {

  const isLoggedIn = useSelector(state => !!state.auth.token)

  p('return HomeNav')
  return(
    <>
      <Update />

      {isLoggedIn ? (
        <>

          <NavIconButton
            to='QRCodeScanner'
            name='qrcode'
            label={t('general.qrcodeScanner')}
          />

          <BookingsIconButton />

          <NavIconButton
            to='UserGroups'
            name='users'
            label={t('models.group')}
          />

        </>
      ) : (
        <NavIconButton
          to='SignInMethods'
          name='sign-in-alt'
          label={t('actions.signIn')}
        />
      )}

      <NavIconButton regular
        to='Support'
        name='question-circle'
        label={t('general.support')}
      />

      {isLoggedIn ?  <UserIconButton /> : null}
    </>
  )

}

export default memo(HomeNav, ()=> true)
