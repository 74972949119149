import React from 'react'
import { PropTypes } from 'prop-types'
import Text from 'components/Text'

import { useSelector } from 'react-redux'

const UserName = ({
  userId,
  size,
  style,
  bold,
}) => {

  const name = useSelector(state => state.entities.users[userId]?.name)

  return (
    <Text
      size={size}
      style={style}
      bold={bold}
    >
      {name}
    </Text>
  )

}

UserName.propTypes = {
  userId: PropTypes.string.isRequired,
  size: PropTypes.string,
  style: PropTypes.object,
  bold: PropTypes.bool,
}

export default UserName
