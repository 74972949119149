import Modal from '@/components/modals/Modal'
import ActivityIndicator from '@/components/ActivityIndicator'
import Background from '@/components/modals/Background'
import Text from '@/components/Text'
import ProgressBar from '@/components/ProgressBar'

import { space, fontSize } from '@/styles/constants'
import Platform from '@/lib/Platform'
import { StyleSheet, View } from 'react-native'

type LoadingCoverProps = {
  loading: boolean,
  label?: string,
  progress?: number,
}

const LoadingCover = ({
  loading = false,
  label,
  progress,
}: LoadingCoverProps) => {

  // fade on iOS will cause modal not to be closed when switching the visible too fast.
  const animationType = Platform.OS === 'ios' ? 'none' : 'fade'

  return (
    <Modal
      visible={loading}
      animationType={animationType}
    >
      <Background style={styles.background} onPress={()=> {}}>
        <ActivityIndicator inverted
          size={fontSize.lg}
        />
        <Text inverted>{label ? label : 'Loading...'}</Text>
        {progress !== undefined && (
          <View style={styles.progress}>
            <ProgressBar progress={progress} />
          </View>
        )}
      </Background>
    </Modal>
  )
}

const styles = StyleSheet.create({
  background: {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  progress: {
    width: '100%',
    marginTop: space[1],
  },
})

export default LoadingCover
