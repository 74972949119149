import { useState, useEffect } from 'react'
import Text from 'components/Text'
import View from 'components/View'

import NetInfo from 'lib/NetInfo'
import styles from 'styles'
import AppState from 'lib/AppState'
import { useNetInfo } from '@react-native-community/netinfo'
import { useSelector, useDispatch } from 'react-redux'
import { set } from 'features/networks'
import { t } from '@/i18n'

const NetworkStatus = () => {

  // Do not use NetInfo.addEventListener is unstable on android.
  const netInfo = useNetInfo()
  const dispatch = useDispatch()

  // isConnected become true when features/requests successfully received
  // network request.
  const isConnected = useSelector(state => {
    const current = state.networks?.isConnected
    // wait untill it is actually false, but not on undefined or null
    return current === false ? false : true
  })

  useEffect(()=> {
    set({ isConnected: netInfo?.isConnected })(dispatch)
  }, [netInfo?.isConnected])

  useEffect(()=> {
    const sub = AppState.addEventListener('change', onAppStateChange)
    return ()=> {
      sub?.remove?.()
    }
  }, [])

  const onAppStateChange = async(e)=> {
    if(e !== 'active') return null
    const netState = await NetInfo.fetch()
    set({ isConnected: netState.isConnected })(dispatch)
  }

  if(!isConnected){

    return (
      <View style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: styles.space.xs,
        marginTop: styles.height.statusBar.height,
        marginBottom: styles.space.xs,
      }}>
        <Text size='xs' center inverted>
          {t('labels.network.noConnection')}
        </Text>
      </View>
    )

  }else{

    return null

  }

}

NetworkStatus.propTypes = {

}

export default NetworkStatus
