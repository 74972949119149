import { memo } from 'react'
import View from 'components/View'
import Link from 'components/Link'
import IconWithLabel from 'components/IconWithLabel'
import useDynamicGrid from 'hooks/useDynamicGrid'

const NavIconButton = props => {

  const width = useDynamicGrid({ size: 1 }).width * 1.6

  const {
    to,
    params,
    name,
    onPress,
    badge,
    label,
    regular,
    style,
    external,
  } = props

  p('return NavIconButton', to, name)
  return (
    <View center style={{
      width,
      ...style
    }}>
      <Link
        external={external}
        to={to}
        params={params}
        onPress={onPress}
      >
        <IconWithLabel
          regular={regular}
          name={name}
          badge={badge}
          label={label}
          containerStyle={{
            justifyContent: 'center',
          }}
        />
      </Link>
    </View>
  )

}

const areEqual = (prevProps, nextProps) => {
  return prevProps.to === nextProps.to &&
    prevProps.name === nextProps.name &&
    prevProps.label === nextProps.label &&
    prevProps.badge === nextProps.badge
}

export default memo(NavIconButton, areEqual)
