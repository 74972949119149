import React from 'react'
import View from 'components/View'
import { styles } from 'styles'
import Flash from './Flash'
import NetworkStatus from 'components/announcements/NetworkStatus'

const AnnouncementContainer = () => {

  return (
    <>
      <NetworkStatus />
      <View style={{
        ...styles.fixed.top,
        top: styles.height.header.height + styles.space.sm,
        zIndex: 3,
      }}>
        <View style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <Flash />
        </View>
      </View>
    </>
  )

}

export default AnnouncementContainer
