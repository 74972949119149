import * as React from 'react'
import { View as NativeView } from 'react-native'

const View = props => {

  const {
    flex,
    center,
    vcenter,
    vend,
    vtop,
    row,
    spaceBetween,
    spaceAround,
    hcenter,
    hstart,
    hend,
    style,
    children,
    fitToChildren,
    viewRef,
    wrap,
    shrink,
    ...rest
  } = props


  return (
    <NativeView
      ref={viewRef}
      style={{
        ...(flex && { flex: 1 }),
        ...(center && {
          alignItems: 'center',
          justifyContent: 'center',
        }),
        ...(row ? {
          flexDirection: 'row',
          ...(spaceBetween && { justifyContent: 'space-between', }),
          ...(spaceAround && { justifyContent: 'space-around', }),
          ...(vcenter && { alignItems: 'center' }),
          ...(vend && { alignItems: 'flex-end' }),
          ...(vtop && { alignItems: 'flex-start' }),
          ...(hend && { justifyContent: 'flex-end' }),
          ...(hcenter && { justifyContent: 'center' }),
          ...(hstart && { justifyContent: 'flex-start' }),
        } : { // non row
          ...(vcenter && { justifyContent: 'center' }),
          ...(vtop && { justifyContent: 'flex-start' }),
          ...(vend && { justifyContent: 'flex-end' }),
          ...(hcenter && { alignItems: 'center' }),
          ...(hend && { alignItems: 'flex-end' }),
        }),
        ...(fitToChildren ? { alignSelf: "flex-start" } : {}),
        ...(wrap ? { flexWrap: 'wrap' } : {}),
        // shrink is 1 on web but 0 on native so be aware
        ...(shrink ? { flexShrink: 1 } : {}),
        ...style,
      }}
      {...rest}
    >
      {children}
    </NativeView>
  )
}

export default View
