import {
  Animated,
  StyleSheet,
  ViewStyle,
} from 'react-native'
import { color, space, } from '@/styles/constants'

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.lightBlue[100],
    marginTop: space[0.5],
    borderRadius: space[0.5],
    height: space[1],
  }
})

const ProgressBar = ({
  progress,
  style,
}: {
  progress: number // 0-1
  style?: ViewStyle
}) => {

  if(isNaN(progress)) {
    return null
  }

  if(progress < 0 || progress > 1) {
    console.warn('ProgressBar progress must be between 0 and 1')
    return null
  }
  const width = new Animated.Value(progress)

  return (
    <Animated.View style={[
      styles.container,
      style,
      {
        width: width.interpolate({
          inputRange: [0, 1],
          outputRange: ['0%', '100%']
        })
      }
    ]} />
  )

}

export default ProgressBar
