import { initializeApp } from 'firebase/app'
import {
  getAnalytics,
  isSupported,
  logEvent,
} from 'firebase/analytics'
import Configs from '@/configs/Configs'
import { Analytics } from './index'
import { checkParams, checkName } from './check'

// configure and initialize Firebase for web (copy this from the Firebase Console Project Settings for the exact values)

let app = null
let webAnalytics: any = null

isSupported().then((supported) => {
  if (supported) {
    app = initializeApp(Configs.firebase)
    webAnalytics = getAnalytics(app)
  }
})

// keep the interface consistent with the native Firebase Analytics
const analytics = (): Analytics => {
  return {
    logEvent: (name, params) => {
      try{
        const n = checkName(name)
        checkParams(params)

        logEvent(webAnalytics, n, params)
      }catch(e){
        p('Error logging event', e)
      }
    }
  }
}

export default analytics
