import { forwardRef } from 'react'
import { PropTypes } from 'prop-types'

import Form from 'components/Form'
import View from 'components/View'
import Icon from 'components/Icon'

import { styles } from 'styles'

const renderSearchForm = (props, ref) => {

  const {
    onChangeText,
    onSubmit,
    value,
    placeholder,
  } = props

  return (
    <View flex row vcenter>
      <Icon label name='search' />
      <Form borderless
        inputRef={ref}
        containerStyle={{ flex: 1 }}
        inputStyle={{ minHeight: undefined }}
        fontSize={styles.size.md}
        placeholder={placeholder}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmit}
        blurOnSubmit={true}
        value={value}
        returnKeyType='search'
        autoCorrect={false}
      />
    </View>
  )

}

const SearchForm = forwardRef(renderSearchForm)

SearchForm.propTypes = {
  onChangeText: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default SearchForm
