import Sentry from '@/lib/Sentry'

import supabase from '@/features/supabase'
import { capture as captureWithWebhook } from '@/utils/error'

// This is a simple capture function that does not access SecureStorage.
// Use this to avoid circular dependency.
const capture = (e: unknown) => {
  captureWithWebhook(e, {})
  Sentry.withScope((scope) => {
    scope.setTag("captureHandled", "true")
    scope.setLevel('warning')
    Sentry.captureException(e)
  })
}

export default capture
