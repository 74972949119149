
import { load, show } from './interstitial'

export const ads = {
  loadInterstitial: load,
  showInterstitial: show,
}

const initialState = {
  interstitial: {
    loaded: false,
  },
  webInterstitial: {
    shown: false
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ads/interstitial/loaded': {
      return {
        ...state,
        interstitial: {
          ...state.interstitial,
          loaded: action.loaded,
        }
      }
    }

    case 'ads/webInterstitial/shown': {
      return {
        ...state,
        webInterstitial: {
          ...state.webInterstitial,
          shown: action.shown,
        }
      }
    }

    default:
      return state
  }
}

