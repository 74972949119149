import { array } from 'utils/array'

export const types = {
  prependId: 'accounts/prependId',
  removeId: 'accounts/removeId',
  clearIds: 'accounts/clearIds',
}

const initialState = {
  ids: []
}

// accounts is persisted, so do not store sensitive information
export default (state = initialState, action) => {
  switch (action.type) {

    case types.prependId: {
      const uniqIds = array.uniq([ action.id, ...state.ids, ]).filter(id => !!id)
      return{
        ...state,
        ids: uniqIds
      }
    }

    case types.removeId: {
      const removed = state.ids.filter(id => id !== action.id)
      return {
        ...state,
        ids: removed
      }
    }

    case types.clearIds: {
      return {
        ...state,
        ids: []
      }
    }

    default: {
      return state
    }
  }
}
