import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { storage } from 'features/auth/storage'

const useAuth = ()=> {

  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)

  const load = async ()=> {
    p('useAuth.load')
    const auth = await storage.getAuth()
    dispatch({ type: 'auth/set', payload: auth })
    setLoaded(true)
  }

  useEffect(()=> {
    load()
  }, [])

  p('return useAuth', loaded)
  return loaded

}

export default useAuth
