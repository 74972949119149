import { combineReducers } from '@reduxjs/toolkit'

import authReducer from '@/features/auth'
import entitiesReducer from '@/features/entities'
import errorsReducer from '@/features/errors'
import updatesReducer from '@/features/updates'
import syncReducer from '@/features/sync'
import flashReducer from '@/features/flash'
import permissionsReducer from '@/features/permissions'
import notificationsReducer from '@/features/notifications'
import routesReducer from '@/features/routes'
import draftsReducer from '@/features/drafts'
import searchesReducer from '@/features/searches'
import paginationsReducer from '@/features/paginations'
import tutorialsReducer from '@/features/tutorials'
import homeReducer from '@/features/home'
import goodsReducer from '@/features/goods'
import networksReducer from '@/features/networks'
import devicesReducer from '@/features/devices'
import requestsReducer from '@/features/requests'
import accountsReducer from '@/features/accounts'
import bookingsReducer from '@/features/bookings'
import adsReducer from '@/features/ads'

const rootReducer = combineReducers({
  auth: authReducer,
  entities: entitiesReducer,
  errors: errorsReducer,
  updates: updatesReducer,
  sync: syncReducer,
  flash: flashReducer,
  permissions: permissionsReducer,
  notifications: notificationsReducer,
  routes: routesReducer,
  drafts: draftsReducer,
  searches: searchesReducer,
  paginations: paginationsReducer,
  tutorials: tutorialsReducer,
  home: homeReducer,
  goods: goodsReducer,
  networks: networksReducer,
  devices: devicesReducer,
  requests: requestsReducer,
  accounts: accountsReducer,
  bookings: bookingsReducer,
  ads: adsReducer,
})

export default rootReducer
