
export const set = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.set,
      payload
    })
  }
}

export const types = {
  set: 'devices/set',
}

const initialState = {
  sessionTimeoutInSecs: null,
  defaultHomeScreen: null,
  accountsDisabled: false,
  cameraType: null, // back, front
  pushNotificationsDisabled: false,
  windowWidth: null,
  windowHeight: null,
}


export default (state = initialState, action) => {
  switch (action.type) {
    case types.set: {
      return{
        ...state,
        ...action.payload
      }
    }
    case types.clear: {
      return initialState
    }
    default: {
      return state
    }
  }
}
