import React, { useState, useRef, useEffect } from 'react'
import View from './View'
import Label from './Label'
import TouchableOpacity from './TouchableOpacity'
import Icon from 'components/Icon'

import { TextInput } from 'react-native'
import { styles, size, space, radius } from 'styles'
import useColorScheme from 'hooks/useColorScheme'
import { PropTypes } from 'prop-types'
import Platform from 'lib/Platform'

const Form = props => {

  const theme = useColorScheme()

  const {
    autoFocus,
    backgroundColor,
    borderless,
    disableAutoComplete,
    email,
    fontSize = size.md,
    help,
    inputRef,
    label,
    labelInverted,
    nativeID,
    numberOfLines,
    numeric = false,
    onChangeText,
    onPressCancel,
    optional,
    password,
    placeholder,
    required,
    showCancel = false,
    testId = '',
    textCentered,
    textContentType: _,
    value,
    ...rest
  } = props

  const maybeRef = useRef(null)
  useEffect(()=> {
    if(autoFocus && !!maybeRef.current){
      maybeRef.current.focus()
    }
  }, [])

  let multiline = !!numberOfLines

  const fontHeight = fontSize * (numberOfLines ? numberOfLines : 1)
  const minHeight = fontHeight + fontSize * 1
  const maxHeight = fontHeight + fontSize * 1

  const handleChangeText = (v) => {
    onChangeText(v)
  }

  let textContentType = props.textContentType || 'none'
  let autoComplete = 'off'
  let keyboardType = props.keyboardType
  // FIXME: importantForAutofill is set for android but not showing the autocomplete
  let importantForAutofill = 'auto'
  if(password){
    multiline = false
    textContentType = 'password'
    autoComplete = 'password'
    importantForAutofill = 'yes'
  }
  if(password && disableAutoComplete){
    autoComplete = Platform.select({
      android: 'password-new',
      web: 'new-password',
      ios: 'off', // autoComplete on iOS is ignored
    })
  }
  if(email){
    multiline = false
    textContentType = 'emailAddress'
    keyboardType = 'email-address'
    autoComplete = 'email'
    importantForAutofill = 'yes'
  }
  let containerStyle = props.containerStyle
  let inputStyle = props.inputStyle
  if(numeric){
    keyboardType = 'number-pad'
    containerStyle = {
      maxWidth: styles.width.two.width,
      ...containerStyle,
    }
    inputStyle = {
      textAlign: 'center',
      ...inputStyle,
    }
  }

  const [secureTextEntry, setSecureTextEntry] = useState(password)

  const handlePressCancel = ()=> {
    if(onPressCancel){
      onPressCancel()
    }else{
      handleChangeText('')
      inputRef.current?.focus()
    }
  }

  const showCancelButton = showCancel && (onPressCancel || value?.length > 0)

  return (
    <>

      {label ? (
        <Label
          text={label}
          inverted={labelInverted}
          shadow={labelInverted}
          required={required}
          optional={optional}
          help={help}
        />
      ): null}

      <View style={{
        width: '100%',
        ...containerStyle,
      }}>

        <TextInput testId={`text-input${testId ? `-${testId}` : ''}`}
          nativeID={nativeID}
          ref={inputRef || maybeRef}
          style={{
            paddingLeft: space.sm,
            paddingRight: space.sm,
            ...(!borderless ? {
              borderWidth: 1,
              borderColor: styles.color[theme].onBackground,
              borderStyle: 'solid',
            } : {} ),
            color: styles.color[theme].onBackground,
            backgroundColor: backgroundColor || styles.color[theme].background,
            fontSize,
            minHeight,
            maxHeight,
            borderRadius: radius.sm,
            ...(multiline ? {
              textAlignVertical: 'top',
              paddingTop: space.sm,
              paddingBottom: space.sm,
            } : {}),
            ...(textCentered ? {
              textAlign: 'center'
            }: {}),
            ...inputStyle
          }}
          autoCapitalize='none'
          multiline={multiline}
          onChangeText={handleChangeText}
          placeholder={placeholder}
          secureTextEntry={secureTextEntry}
          textContentType={textContentType}
          autoComplete={autoComplete}
          placeholderTextColor={'#bbb'}
          value={value}
          keyboardType={keyboardType}
          importantForAutofill={importantForAutofill}
          {...rest}
        />

        {showCancelButton ? (
          <View center style={{
            ...styles.fixed.right,
            marginRight: styles.space.md
          }}>
            <TouchableOpacity testId={`button-cancel-${testId}`}
              onPress={handlePressCancel}
            >
              <Icon regular label name='times-circle' />
            </TouchableOpacity>
          </View>
        ) : null}

        {password ? (
          <View center style={{
            ...styles.fixed.right,
            marginRight: styles.space.md
          }}>
            <TouchableOpacity onPress={
              ()=> setSecureTextEntry(!secureTextEntry)
            }>
              <Icon regular label name={secureTextEntry ? 'eye-slash' : 'eye'} />
            </TouchableOpacity>
          </View>
        ) : null}

      </View>
    </>
  )

}

Form.propTypes = {
  backgroundColor: PropTypes.string,
  borderless: PropTypes.bool,
  containerStyle: PropTypes.object,
  email: PropTypes.bool,
  fontSize: PropTypes.number,
  help: PropTypes.object,
  inputStyle: PropTypes.object,
  keyboardType: PropTypes.string,
  label: PropTypes.string,
  labelInverted: PropTypes.bool,
  numberOfLines: PropTypes.number,
  numeric: PropTypes.bool,
  onChangeText: PropTypes.func.isRequired,
  password: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showCancel: PropTypes.bool,
  testId: PropTypes.string, // it will be merged into testId: 'text-input-${testId}'
  textCentered: PropTypes.bool,
  textContentType: PropTypes.string,
  value: PropTypes.string.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  autoFocus: PropTypes.bool,
  onPressCancel: PropTypes.func,
}

export default Form
