import {
  useWindowDimensions as nativeDimensions
} from 'react-native'
import { wideThreshold } from 'styles'
import Platform from 'lib/Platform'

const useWindowDimensions = ()=> {

  let {
    width,
    height,
    ...rest
  } = nativeDimensions()

  if(Platform.OS === 'web' && width > wideThreshold){
    width = wideThreshold
  }

  return {
    width,
    height,
    isHorizontal: width / height > 1,
    ...rest,
  }

}

export default useWindowDimensions
