import i18n from 'i18n-js'
import Localization from '@/lib/Localization'
// Set the key-value pairs for the different languages you want to support.
import ja from '@/locales/ja.json'
import hira from '@/locales/hira.json'
import en from '@/locales/en.json'
import { getItemAsync } from '@/lib/SecureStorage'
import ExpoConstants from '@/configs/ExpoConstants'

const appName = ExpoConstants.expoConfig?.extra?.APP_NAME || 'Casican'
const appNameJp = ExpoConstants.expoConfig?.extra?.APP_NAME_JP || 'カシカン'

// Helper function to safely access nested object properties
const safelyGetNestedProperty = (obj:any, path:any) => {
  return path.reduce((acc: any, key:string) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj)
}

// Helper function to set a nested object property by a string path
const setNestedProperty = (obj: any, path: string[], value: string) => {
  let lastKeyIndex = path.length - 1
  for (let i = 0; i < lastKeyIndex; ++i) {
    let key = path[i]
    if (!(key in obj)) obj[key] = {}
    obj = obj[key]
  }
  obj[path[lastKeyIndex]] = value
}

// Function to replace placeholders in specified paths of an object
export const replacePlaceholdersInPaths = (obj:any, paths:any) => {
  paths.forEach((stringPath:string) => {
    const path = stringPath.split('.')
    const valueAtPath = safelyGetNestedProperty(obj, path)

    // Process the value if it's a string
    if (typeof valueAtPath === 'string') {
      const replacedValue = valueAtPath
        .replace(/#{appName}/g, appName)
        .replace(/#{appNameJp}/g, appNameJp)
      setNestedProperty(obj, path, replacedValue)
    }
  })
}

// specify the paths to replace placeholders to improve performance
const paths = [
  'appName',
  'labels.search.blog',
  'footer.howToUse',
  'footer.howToUseVideos',
  'footer.slide',
  'sales.achieves',
  'landing.fv.copy',
  'landing.startNow.description',
  'lp.features.card.extraText',
  'nps.question',
  'helps.good.forReferenceOnly',
  'helps.support.pleaseAsk',
  'helps.authToken.createUser',
  'tutorials.pages.1.description'
]

// I don't think this is necessary but just to make sure
let initialized = false
if(!initialized){
  replacePlaceholdersInPaths(ja, paths)
  replacePlaceholdersInPaths(en, paths)
  replacePlaceholdersInPaths(hira, paths)
  initialized = true
}

// jw is a deprecated language code so used in place of hiragana
export const translations = {
  ja,
  jw: hira,
  en,
  undefined: en,
  null: en
}

i18n.translations = translations
// Set the locale once at the beginning of your app.
// Android device returns like ja-JP so it does not match
const localeToKey = (locale: string) => {
  if(locale?.indexOf('ja') > -1) return 'ja'
  // default to en
  return 'en'
}

export const defaultLocale = localeToKey(Localization.locale)

// retrieve the use preference from storage
// it's called at App.js
export const initLocale = async() => {
  i18n.locale = defaultLocale

  const picked = await getItemAsync('i18n')
  if(picked){
    i18n.locale = picked
  }

  // url override for SEO, currently only for LP
  const url = window?.location?.href || ''
  if (url.indexOf('lp/ja') > -1) i18n.locale = 'ja'
  if (url.indexOf('lp/en') > -1) i18n.locale = 'en'
  if (url.indexOf('lp/jw') > -1) i18n.locale = 'jw'
}

i18n.locale = defaultLocale

export const t = i18n.t

export default i18n
