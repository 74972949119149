import { flash } from 'features/flash'
import { capture } from 'sentry'
import { objToQueryString } from 'utils'
//import NetInfo from 'lib/NetInfo'
import { t } from '@/i18n'
import { set as setNetworks } from 'features/networks'
import { signOutAndNavigate } from 'features/auth'
import { navigate } from 'navigation/RootNavigation'

// https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object
const flattenObject = (obj) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

const ignoreStatuses = [400, 401, 403, 404]

const captureResponse = async(
  response,
  requestData,
) => {
  try{
    if(response.clone){
      const cloned = response.clone()
      const status = cloned.status
      const headers = flattenObject(
        Object.fromEntries(cloned.headers.entries())
      )
      const body = await cloned.json()
      const extra = {
        ok: cloned.ok,
        status, headers,
        body: JSON.stringify(body),
        request: requestData,
      }
      const e = new Error(`status: ${status}`)
      e.name = `${status}`
      // too many 400s
      if(!ignoreStatuses.includes(status)){
        capture(e, extra)
      }
    }else{
      const e = new Error(`response.clone has failed`)
      capture(e)
    }
  }catch(err){
    capture(err)
  }
}

const handleErrorResponse = async(
  response,
  requestData,
  dispatch,
) => {
  switch (response.status){
    case 400: {
      flash.add({ type: 'error', body: t('errors.status.400') })(dispatch)
      break
    }
    case 401: {
      // flash.add({ type: 'error', body: t('errors.status.401') })(dispatch)
      // setTimeout(()=> { handlePurge()(dispatch) }, 2000)
      break
    }
    case 403: {
      flash.add({ type: 'error', body: t('errors.status.403') })(dispatch)
      break
    }
    case 404: {
      // 404 is mainly handled in useCachedRead, to navigate to NotFound screen
      break
    }
    case 444: {
      // custom response which will return response.ok = false but not throw error or show flash messages
      // just siliently ignore the response
      break
    }
    case 481: {
      // custom response which will be triggerd when user has requested a resource
      // with limited authorization (jwt with jti)
      flash.add({ type: 'error', body: t('errors.status.481') })(dispatch)
      break
    }
    case 500: {
      flash.add({ type: 'error', body: t('errors.status.500') })(dispatch)
      break
    }

    case 503: {
      flash.add({ type: 'error', body: t('errors.status.503') })(dispatch)
      break
    }
  }

  if(!response){
    flash.add({ type: 'error', body: t('errors.noResponse'), })(dispatch)
  }
  await captureResponse(response, requestData)
}

// you can call multiple json() on this
const handleFetch = async({
  method,
  dispatch,
  getState, // is is passed but not in use now
  endpoint,
  params,
  payload,
  signal, // currently supporeted only in GET, add support for POST, PUT, DELETE if you need
}) => {
  const token = getState().auth?.token
  const queryString = objToQueryString(params)
  p(method, endpoint, payload, token)

  // it is unstable with android device.
  // 端末のオンライン / オフラインをチェックしてオフラインならリクエストしない
  //const netState = await NetInfo.fetch()
  //if(!netState.isConnected){
  //  flash.add({ type: 'error', body: 'ネットワーク接続がありません' })(dispatch)
  //  return { ok: false }
  //}

  try{
    const sessionTimeoutInSecs = getState().devices?.sessionTimeoutInSecs
    const lastRequestAt = getState().requests?.lastRequestAt

    if(sessionTimeoutInSecs && lastRequestAt && token){
      const diffInSecs = (Date.now() - lastRequestAt) / 1000
      if(diffInSecs >= sessionTimeoutInSecs){
        signOutAndNavigate()(dispatch, getState)
        flash.add({ type: 'error', body: t('errors.sessionTimeout') })(dispatch)
        return
      }
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    }
    const response = await fetch(`${endpoint}?${queryString}`, {
      method,
      headers,
      body: JSON.stringify(payload),
      signal,
    })

    p('response.ok', response?.ok)
    set({ lastRequestAt: Date.now() })(dispatch)
    if(!response?.ok){
      await handleErrorResponse(
        response,
        { payload, queryString, endpoint, method, headers },
        dispatch
      )
    }else{
      // turn the NetworkStatus as connected.
      setNetworks({ isConnected: true })(dispatch)
    }
    return response
  }catch(err){
    ppError(err)
    flash.add({ type: 'error', body: t('errors.noResponse') })(dispatch)
    capture(err)
    ppError(err)
  }
}

export const post = (endpoint, payload) =>{
  return async(dispatch, getState) =>{
    return handleFetch({
      method: 'POST',
      dispatch,
      getState,
      endpoint,
      payload,
    })
  }
}

export const get = (endpoint, params, options) =>{
  return async(dispatch, getState) =>{
    return handleFetch({
      method: 'GET',
      dispatch,
      getState,
      endpoint,
      params,
      signal: options?.signal,
    })
  }
}

export const put = (endpoint, payload) =>{
  return async(dispatch, getState) =>{
    return handleFetch({
      method: 'PUT',
      dispatch,
      getState,
      endpoint,
      payload,
    })
  }
}

export const destroy = (endpoint) =>{
  return async(dispatch, getState) =>{
    return handleFetch({
      method: 'DELETE',
      dispatch,
      getState,
      endpoint,
    })
  }
}

export const requests = {
  post, get, put, destroy,
}

export const set = payload => {
  return (dispatch) => {
    dispatch({
      type: types.set,
      payload
    })
  }
}

export const types = {
  set: 'requests/set',
}

const initialState = {
  lastRequestAt: null
}


export default (state = initialState, action) => {
  switch (action.type) {
    case types.set: {
      return{
        ...state,
        ...action.payload
      }
    }
    default: {
      return state
    }
  }
}
