import * as NativeLinking from 'expo-linking'
import Platform from 'lib/Platform'

const Linking = {
  ...NativeLinking,
  openURL: (url)=> {
    if(Platform.OS === 'web'){
      window.open(url, '_blank')
    }else{
      NativeLinking.openURL(url)
    }
  }
}


export default Linking
