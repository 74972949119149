import React from 'react'
import * as Updates from 'expo-updates'
import sentry from './sentry'
import { capture as captureWithWebhook } from 'utils/error'
import { PropTypes } from 'prop-types'
import Configs from 'configs/Configs'
import { store } from '@/store'
import Platform from 'lib/Platform'
import { View } from 'react-native'

class RootErrorBoundary extends React.Component {

  state = { error: null }

  componentDidCatch(error, errorInfo) {
    console.log('componentDidCatch')
    console.error(error)
    this.setState({ error })

    captureWithWebhook(error, errorInfo)

    if(Configs.env !== 'development'){

      sentry.withScope((scope) => {
        scope.setTag("ErrorBoundary", "true")
        scope.setLevel('error')
        if(errorInfo){
          for (const key in errorInfo) {
            scope.setExtra(key, errorInfo[key])
          }
        }
        sentry.captureException(error)
      })

      // reload after all the requests are done
      setTimeout(()=> {
        this.reload()
      }, 500)
    }
  }

  reload = async() => {
    // clear other than drafts and auth
    store.dispatch({ type: 'entities/purge' })
    store.dispatch({ type: 'home/clear' })
    store.dispatch({ type: 'errors/clear' })
    store.dispatch({ type: 'paginations/clear' })
    store.dispatch({ type: 'permissions/clear' })
    store.dispatch({ type: 'routes/clear' })
    store.dispatch({ type: 'searches/clear' })
    store.dispatch({ type: 'sync/clear' })
    store.dispatch({ type: 'tutorials/clear' })
    store.dispatch({ type: 'updates/clear' })
    store.dispatch({ type: 'drafts/removeCreatingGood' })
    if(Platform.OS === 'web'){
      window.location = '/'
    }else{
      await Updates.reloadAsync()
    }
  }

  render(){
    if(this.state.error){
      return <View />
    }else{
      return this.props.children
    }
  }

}

RootErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RootErrorBoundary
