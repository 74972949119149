import { createIconSetFromIcoMoon } from '@expo/vector-icons'
import { size as baseSize } from '@/styles'

// assets/[name].ttf is generated using
// https://icomoon.io/

const Icon = createIconSetFromIcoMoon(
  require('assets/selection.json'),
  'fontawesome6',
  'fontawesome6.ttf'
)

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const FontIcon = ({
  name,
  size='md',
  color='#000',
}: {
  name: string,
  size?: Size,
  color?: string
}) => {

  let sizeInt = baseSize[size]

  return(
    <Icon name={name} size={sizeInt} color={color} />
  )

}

export default FontIcon
